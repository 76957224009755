import {inject} from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';
import {UniversalEntitySelect} from '../../dialog/universal-entity-select';
import {Client} from '../../api/client';
import {FlashService} from '../../flash/flash-service';
import {I18N} from 'aurelia-i18n';
import * as _ from 'lodash';

@inject(DialogService, Client, FlashService, I18N)
export class MediaDatabaseSelect
{
    constructor(dialogService, client, flash, i18n)
    {
        this.dialogService = dialogService;
        this.client = client;
        this.flash = flash;
        this.i18n = i18n;
    }

    initialize(tinymce)
    {
        //This gets overwritten in callback so declaring locally
        let dialogService = this.dialogService;
        let client = this.client;
        let flash = this.flash;
        let i18n = this.i18n;

        tinymce.PluginManager.add('mediadatabaseselect', function(editor, url) {
            // Add a button that opens a window
            editor.addButton('mediadatabaseselect', {
                tooltip: i18n.tr('media_database.select'),
                icon: ' overwrite-fa-font  fa fa-photo-film',
                onclick: () => {

                    dialogService.open({
                        viewModel: UniversalEntitySelect,
                        model: {selectModelId: 'media-database/media-item'}
                    }).whenClosed(data => {

                        let items = data.output;

                        if (!items || items.length == 0) {
                            return null;
                        }

                        console.log('items', items);

                        let ids = _.map(items, (e) => {
                            return e.id;
                        });

                        client.get('media-database/media-item?conditions=' + JSON.stringify({id: {$in: ids}}) + '&embeds[]=file').then(data => {

                            _.each(data.items, (item) => {

                                if (item.file.previewUrl.includes('access_token')) {
                                    throw new Error('Access token in url, image is not public, e.g. url should not be html.');
                                }

                                editor.insertContent('<img src="'+ item.file.previewUrl.replace('$width', '600').replace('$height', '450') +'">');

                            });

                        }).catch(error => {
                            flash.error(i18n.tr('media_database.error-html-editor-image-select'));
                        });
                    });
                }
            });
        });
    }

}
